import React, { useState, Component, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import logo from "../style/images/Hair-Style-Client-Head-Logo.svg";
import * as mutations from "../store/mutations";
import * as moment from "moment";
import {LoadingSpinnerConnect} from "./LoadingSpinner";
import InfiniteScroll from 'react-infinite-scroller';

// import ConnectedSalonInformation from "./SalonInformation";
// import ConnectedCustomPager from "./CustomPager";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ConnectedSalonInformation = React.lazy(() =>
  import(
    /* webpackChunkName: "ConnectedSalonInformation" */
    /* webpackMode: "lazy" */ "./SalonInformation"
  )
);

const ConnectedCustomPager = React.lazy(() =>
  import(
    /* webpackChunkName: "ConnectedCustomPager" */
    /* webpackMode: "lazy" */ "./CustomPager"
  )
);

const MainContainerComponent = ({
  authenticateUser,
  getSalonsPaged,
  salons,
  getAllHairstylists,
  totalCount
}) => {
  let [tempSalons, setTempSalons] = useState([]);
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [showNoSalons, setShowNoSalons] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);

  const [visitDate, setVisitDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const debounceGetSalonsPaged = React.useCallback(
    debounce(getSalonsPaged, 700),
    []
  );

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const handleSalonInformationClick = (index) => {
    if (!tempSalons[index].isOpened) {
      let temp = tempSalons.map((s) => {
        s.isOpened = false;
        return s;
      });
      temp[index].isOpened = true;
      setTempSalons(temp);
      getAllHairstylists(temp[index].salonId);
    }
  };

  const setCustomerState = (fullName, visitDate, startTime, endTime) => {
    setFullName(fullName);
    setVisitDate(visitDate);
    setStartTime(startTime);
    setEndTime(endTime);
  };

  const loadFunc = (pageNumber) => {
    setPage(pageNumber);
    getSalonsPaged(search, pageNumber, []);
  };

  useEffect(
    () =>
      authenticateUser(() => {
        debounceGetSalonsPaged("", 1, []);
      }),
    []
  );
  useEffect(() => {
    if (salons && salons.length) {
      let temp = salons.map((s) => {
        s.isOpened = salons.length > 1 ? false : true;
        return s;
      });
      setTempSalons(temp);
    }
    else {
      setTempSalons([]);
    }
    if (salons && salons.length === 1) {
      getAllHairstylists(salons[0].salonId);
    }
    if(salons && salons.length >= 5){
      setShowSearch(true);
    }
    if(salons && salons.length === 0){
      setShowNoSalons(true);
    }

  }, [salons]);
  return (
    <>
      {/* <button
        data-toggle="modal"
        data-target="#visitInformation"
        hidden={true}
        id="hiddenButtonForVisitInformationModal"
        onClick={e => {
          e.preventDefault();
        }}
      ></button> */}
      <ToastContainer />
      {/* <LoadingSpinnerConnect /> */}

      <header>
        {/* <div className="main-header"> */}
        <div
          className={`main-header ${
            showSearch ? "" : " no-search"
          }`}
        >

      {/* <div className="header-action">  
          <button
          className="btn btn-purple-outline btn-video ico-l"
          type="button"
          data-toggle="modal"
          data-target="#promoVideoModal"
          ><i className="fab fa-youtube"></i> Promo Video</button>         
      </div> */}

            <div className="logo-header">
            <img
              className="logo-hairstylist-client"
              src={logo}
              alt="Hairstylist logo"
            />
          </div>



          <p className="brand-logo-title">Hairstylist</p>
          <div className="search-holder sh-dark">
            {showSearch && (
              <>
                <input
                  type="text"
                  id="inputEmail"
                  className="form-control"
                  placeholder="Search..."
                  value={search}
                  onChange={(event) => {
                    setPage(1);
                    setSearch(event.target.value);
                    debounceGetSalonsPaged(event.target.value, 1, sort);
                  }}
                />
                <>
                  {search && (
                    <button
                      type="button"
                      className="btn ico-btn-clear"
                      onClick={(e) => {
                        setPage(1);
                        setSearch("");
                        debounceGetSalonsPaged("", 1, sort);
                      }}
                    >
                      <i className="fal fa-times"></i>
                    </button>
                  )}
                </>

                <button type="button" className="btn ico-btn-search">
                  <i className="fal fa-search"></i>
                </button>
              </>
            )}
          </div>
        </div>
      </header>
      <div className="content">
      <LoadingSpinnerConnect />
        <InfiniteScroll
          pageStart={1}
          loadMore={loadFunc}
          hasMore={totalCount - (page * 10) > 0}
        >
          {tempSalons.map((s, index) => {
            return (
              <Suspense key={s.salonId} fallback={<div style={{ display: "none" }}></div>}>
                <ConnectedSalonInformation
                  handleClick={() => handleSalonInformationClick(index)}
                  salon={s}
                  setParentState={setCustomerState}
                />
              </Suspense>
            );
          })}
        </InfiniteScroll>
      </div>
      {showNoSalons && <div style={{textAlign: "center"}}>No salons found</div>}
      <footer className="main-footer">
        {/* <p className="copyright">All rights reserved &copy; 2019 Hairstylist</p> */}
        <div className="container copyright">
          <span className="">
            Hairstylist &copy; Copyright {moment().format("YYYY")}, Version:{" "}
            {process.env.VERSION}
          </span>
        </div>
      </footer>

      {/* Visit creation information modal */}
      <div
        className="modal fade"
        id="visitInformation"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="visitInformationLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="visitInformationLabel">
                <i className="fal fa-cut fa-lg c-purple"></i> Poseta je zakazana
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <h2 className="info-title-upp-case-xl icon-custom-top-center">
                <span className="icon-custom is-xl icon-info-circle-blue-white" />
                <span className="c-gray-darker txt-transform-none d-block">
                  Poštovani<span className="opacity-50">/</span>a
                </span>
                <span className="fw-600 d-block">{fullName}</span>
              </h2>
              <div className="info-message text-center">
                <p>Vaša poseta je zakazana za </p>
                <p className="prime-text">
                  <span className="c-gray-darker fw-600">
                    {moment(visitDate).format("DD.MM.YYYY.")}
                  </span>{" "}
                  od{" "}
                  <span className="c-pink fw-600">
                    {moment(startTime).format("HH:mm")}
                  </span>{" "}
                  do{" "}
                  <span className="c-pink fw-600">
                    {moment(endTime).format("HH:mm")}
                  </span>
                </p>
              </div>
            </div>
            <div className="modal-footer">
              {/* <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-check" /> Yes
              </button> */}
              <button
                type="button"
                className="btn btn-outline-success min-w-140 ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-check" /> OK
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Promo Video Modal */}
      <div
        className="modal fade promo-video-modal"
        data-backdrop="static"
        data-keyboard="false"
        id="promoVideoModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="promoVideoModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title pl-0" id="promoVideoModalLabel">
                <i className="fal fa-cut fa-lg c-purple"></i>{" "}
                Hairstylist Promo Video
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  var url = document
                    .getElementById("player")
                    .getAttribute("src");
                  document.getElementById("player").setAttribute("src", null);
                  document.getElementById("player").setAttribute("src", url);
                }}
              >
                <i className="fal fa-times"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="video-responsive-wrapper">
                <iframe
                  id="player"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/TMUgA2_pz84"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                ></iframe>
              </div>
            </div>
            {/* <div className="modal-footer pt-0">
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
                onClick={(e) => {
                  var url = document
                    .getElementById("player")
                    .getAttribute("src");
                  document.getElementById("player").setAttribute("src", null);
                  document.getElementById("player").setAttribute("src", url);
                }}
              >
                <i className="fal fa-times"></i> Close
              </button>
            </div> */}
          </div>
        </div>
      </div>


    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    salons: state.salons,
    totalCount: state.totalCount,
    page: state.page
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateUser(cb) {
    let email = "webuser";
    let password = "test";
    dispatch(mutations.requestAuthenticateUser(email, password, cb));
  },
  getSalonsPaged(searchValue, page, sort) {
    dispatch(mutations.getSalonsPaged(searchValue, page, sort));
  },
  getAllHairstylists(salonId) {
    dispatch(mutations.getAllHairstylistsForDropdown(salonId));
  },
});

export const ConnectedMainContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainContainerComponent);
