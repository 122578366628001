export const REQUEST_AUTHENTICATE_USER = `REQUEST_AUTHENTICATE_USER`;
export const PROCESSING_AUTHENTICATE_USER = `PROCESSING_AUTHENTICATE_USER`;
export const AUTHENTICATING = `AUTHENTICATING`;
export const AUTHENTICATED = `AUTHENTICATED`;
export const NOT_AUTHENTICATED = `NOT_AUTHENTICATED`;
export const SET_STATE = `SET_STATE`;
export const GET_SALONS_PAGED = "GET_SALONS_PAGED";
export const SET_SALONS = "SET_SALONS";
export const SET_LOADED = "SET_LOADED";
export const GET_FREE_TIME_SLOTS = "GET_FREE_TIME_SLOTS";
export const GET_ALL_FREE_VISIT_TIME_SLOTS_V2 =
  "GET_ALL_FREE_VISIT_TIME_SLOTS_V2";
export const GET_ALL_HAIRSTYLISTS_DROPDOWN = "GET_ALL_HAIRSTYLISTS_DROPDOWN";
export const GET_HAIRSTYLIST_WORKING_TIMES = "GET_HAIRSTYLIST_WORKING_TIMES";
export const SET_ALL_HAIRSTYLISTS = "SET_ALL_HAIRSTYLISTS";
export const SET_HAIRSTYLIST_WORKING_TIMES = "SET_HAIRSTYLIST_WORKING_TIMES";
export const GET_HAIRSTYLIST_SERVICE_TYPES = "GET_HAIRSTYLIST_SERVICE_TYPES";
export const SET_HAIRSTYLIST_SERVICE_TYPES = "SET_HAIRSTYLIST_SERVICE_TYPES";
export const SET_FREE_TIME_SLOTS = "SET_FREE_TIME_SLOTS";
export const CREATE_VISIT = "CREATE_VISIT";
export const CREATE_VISIT_FROM_SERVICE_TYPE_LIST_AND_CUSTOMER_DATA =
  "CREATE_VISIT_FROM_SERVICE_TYPE_LIST_AND_CUSTOMER_DATA";

export const requestAuthenticateUser = (email, password, cb) => ({
  type: REQUEST_AUTHENTICATE_USER,
  email,
  password,
  cb
});

export const processAuthenticateUser = (
  status = AUTHENTICATING,
  session = null
) => ({
  type: PROCESSING_AUTHENTICATE_USER,
  session,
  authenticated: status
});

export const getSalonsPaged = (searchValue, page, sort) => ({
  type: GET_SALONS_PAGED,
  searchValue,
  page,
  sort
});
export const setSalons = state => ({
  type: SET_SALONS,
  salons: state.resultList,
  page: state.pageNumber,
  totalCount: state.totalCount
});

export const setState = (state = {}) => ({
  type: SET_STATE,
  state
});
export const setLoaded = loaded => ({
  type: SET_LOADED,
  loaded
});
export const getAllHairstylistsForDropdown = salonId => ({
  type: GET_ALL_HAIRSTYLISTS_DROPDOWN,
  salonId
});
export const getHairstylistWorkingTimes = salonHairstylistId => ({
  type: GET_HAIRSTYLIST_WORKING_TIMES,
  salonHairstylistId
});
export const setHairstylistWorkingTimes = hairstylistWorkingTimes => ({
  type: SET_HAIRSTYLIST_WORKING_TIMES,
  hairstylistWorkingTimes
});
export const setAllHairstylists = hairstylists => ({
  type: SET_ALL_HAIRSTYLISTS,
  hairstylists
});
export const getHairstylistServiceTypes = (hairstylistId, salonId) => ({
  type: GET_HAIRSTYLIST_SERVICE_TYPES,
  hairstylistId,
  salonId
});
export const setHairstylistServiceTypes = hairstylistServiceTypes => ({
  type: SET_HAIRSTYLIST_SERVICE_TYPES,
  hairstylistServiceTypes
});
export const getAllFreeVisitTimeSlots = freeVisitTimeSlotRequest => ({
  type: GET_FREE_TIME_SLOTS,
  freeVisitTimeSlotRequest
});

export const getAllFreeVisitTimeSlotsV2 = freeVisitTimeSlotRequest => ({
  type: GET_ALL_FREE_VISIT_TIME_SLOTS_V2,
  freeVisitTimeSlotRequest
});

export const setAllFreeVisitTimeSlots = freeVisitTimeSlots => ({
  type: SET_FREE_TIME_SLOTS,
  freeVisitTimeSlots
});
// export const createVisit = visit => ({
//   type: CREATE_VISIT,
//   visit
// });

export const createVisitFromServiceTypeListAndCustomerData = (visit, cb) => ({
  type: CREATE_VISIT_FROM_SERVICE_TYPE_LIST_AND_CUSTOMER_DATA,
  visit,
  cb
});
