import { combineReducers } from "redux";
import * as mutations from "./mutations";

let defaultState = {
  session: {},
  activeUser: {},
  salons: null,
  loaded: true,
  hairstylistWorkingTimes: [],
  hairstylists: [],
  hairstylistServiceTypes: [],
  freeVisitTimeSlots: []
};

export const reducer = combineReducers({
  session: (userSession = defaultState.session, action) => {
    let { type, authenticated, session } = action;
    switch (type) {
      case mutations.SET_STATE:
        return { ...session, id: action.state.session.id };
      case mutations.REQUEST_AUTHENTICATE_USER:
        return { ...session, authenticated: mutations.AUTHENTICATING };
      case mutations.PROCESSING_AUTHENTICATE_USER:
        return { ...session, authenticated };
      default:
        return userSession;
    }
  },
  activeUser: (user = defaultState.activeUser, action) => {
    switch (action.type) {
      case mutations.SET_STATE:
        return action.state.activeUser;
      default:
        return user;
    }
  },
  salons(salons = defaultState.salons, action) {
    switch (action.type) {
      case mutations.SET_SALONS:
        if(action.page > 1){
          return [...salons, ...action.salons];
        } else {
          return action.salons
        }
      default:
        return salons;
    }
  },
  page(page = 1, action) {
    switch (action.type) {
      case mutations.SET_SALONS:
        return action.page;
      default:
        return page;
    }
  },
  totalCount(totalCount = 1, action) {
    switch (action.type) {
      case mutations.SET_SALONS:
        return action.totalCount;
      default:
        return totalCount;
    }
  },
  loaded(loaded = defaultState.loaded, action) {
    switch (action.type) {
      case mutations.SET_LOADED:
        return action.loaded;
      default:
        return loaded;
    }
  },
  hairstylistWorkingTimes(
    hairstylistWorkingTimes = defaultState.hairstylistWorkingTimes,
    action
  ) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLIST_WORKING_TIMES:
        return action.hairstylistWorkingTimes;
      default:
        return hairstylistWorkingTimes;
    }
  },
  hairstylists(hairstylists = defaultState.hairstylists, action) {
    switch (action.type) {
      case mutations.SET_ALL_HAIRSTYLISTS:
        return action.hairstylists;
      default:
        return hairstylists;
    }
  },
  hairstylistServiceTypes(
    hairstylistServiceTypes = defaultState.hairstylistServiceTypes,
    action
  ) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLIST_SERVICE_TYPES:
        return action.hairstylistServiceTypes;
      default:
        return hairstylistServiceTypes;
    }
  },
  freeVisitTimeSlots(
    freeVisitTimeSlots = defaultState.freeVisitTimeSlots,
    action
  ) {
    switch (action.type) {
      case mutations.SET_FREE_TIME_SLOTS:
        return action.freeVisitTimeSlots;
      default:
        return freeVisitTimeSlots;
    }
  }
});
