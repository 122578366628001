import React from "react";
// import "@progress/kendo-theme-default/dist/all.css";
import { Route, Router } from "react-router-dom";
import { Provider } from "react-redux";
import { history } from "./store/history";
import { store } from "./store";
import { CookiesProvider } from "react-cookie";
import { ConnectedMainContainer } from "./components/MainContainer";
import "react-datepicker/dist/react-datepicker.css";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

function App() {
  return (
    <CookiesProvider>
      <Router history={history}>
        <Provider store={store}>
          <>
            <Route
              exact
              path="/"
              render={props => <ConnectedMainContainer {...props} />}
            />
          </>
        </Provider>
      </Router>
    </CookiesProvider>
  );
}

export default App;
