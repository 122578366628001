import { put, select, take, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { history } from "./history";
import * as mutations from "./mutations";
import { toast } from "react-toastify";

const url = process.env.URL;

const toastSuccess = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
const toastError = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

/* #region User sagas */
const TOKEN = "token";
const USER_ID = "user_id";

export function* userAuthenticationSaga() {
  while (true) {
    const { email, password, cb } = yield take(
      mutations.REQUEST_AUTHENTICATE_USER
    );
    try {
      const { data } = yield axios.post(url + `/v1.0/login`, {
        Username: email,
        Password: password,
      });

      let state = { session: data.session, activeUser: data.activeUser };

      yield put(mutations.setState(state));
      yield put(
        mutations.processAuthenticateUser(mutations.AUTHENTICATED, {
          id: data.personId,
          token: data.token,
        })
      );

      localStorage.setItem(TOKEN, JSON.stringify(data.token));
      localStorage.setItem(USER_ID, JSON.stringify(data.personId));
      cb();
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error logging in");
      yield put(mutations.processAuthenticateUser(mutations.NOT_AUTHENTICATED));
    }
  }
}

export function* getSalonsPagedTakeEvery() {
  yield takeEvery(mutations.GET_SALONS_PAGED, function* getSalonsPaged(action) {
      yield put(mutations.setLoaded(false));
  
      try {
        let filters = [
          { Property: "Name", Value: action.searchValue, Type: "string" },
        ];
  
        const x = action.sort.map((x) => {
          return {
            Property: x.field[0].toUpperCase() + x.field.slice(1),
            Direction: x.dir.toUpperCase(),
          };
        });
  
        const { data } = yield axios({
          method: "post",
          url: url + "/v1.0/salons/paged",
          data: {
            PageNumber: action.page,
            PageSize: 10,
            Sort: x,
            Filters: filters,
          },
          headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        });
        yield put(mutations.setSalons(data));
        yield put(mutations.setLoaded(true));
      } catch (e) {
        /* catch block handles failed save */
        yield put(mutations.setLoaded(true));
        console.log(e);
        toastError("Error getting salons");
      }
  })
}

export function* getAllHairstylistWorkingTimesSaga() {
  while (true) {
    const { salonHairstylistId } = yield take(
      mutations.GET_HAIRSTYLIST_WORKING_TIMES
    );
    let o = {
      SalonHairstylistId: salonHairstylistId,
    };
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/allWorkingTimes",
        data: { ...o },
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      //toastSuccess("If date is created checked");
      yield put(mutations.setHairstylistWorkingTimes(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Unsecsessefully fetched all hairstylist working times");
    }
  }
}
export function* getAllHairstylistsForDropdownSaga() {
  while (true) {
    const { salonId } = yield take(mutations.GET_ALL_HAIRSTYLISTS_DROPDOWN);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + `/v1.0/hairstylists/dropdown/${salonId}`,
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllHairstylists(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting hairstylists for dropdown");
    }
  }
}

export function* getAllHairstylistServiceTypesForHairstylistSaga() {
  while (true) {
    const { hairstylistId, salonId } = yield take(
      mutations.GET_HAIRSTYLIST_SERVICE_TYPES
    );
    let o = {
      HairstylistId: hairstylistId,
      SalonId: salonId,
    };
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/hairstylistServiceTypes/allHairstylistServiceTypes",
        data: { ...o },
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });

      //toastSuccess("If date is created checked");
      yield put(mutations.setHairstylistServiceTypes(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Unsecsessefully fetched all hairstylist service types");
    }
  }
}

// export function* getAllFreeVisitTimeSlotsSaga() {
//   while (true) {
//     const { freeVisitTimeSlotRequest } = yield take(
//       mutations.GET_FREE_TIME_SLOTS
//     );
//     try {
//       const { data } = yield axios({
//         method: "post",
//         url: url + "/v1.0/freeTimeSlots/visit",
//         data: { ...freeVisitTimeSlotRequest },
//         headers: {
//           Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
//         },
//       });
//       yield put(mutations.setAllFreeVisitTimeSlots(data));
//     } catch (e) {
//       /* catch block handles failed save */
//       console.log(e);
//       toastError("Error getting free time slots");
//     }
//   }
// }

export function* getAllFreeVisitTimeSlotsV2Saga() {
  while (true) {
    const { freeVisitTimeSlotRequest } = yield take(
      mutations.GET_ALL_FREE_VISIT_TIME_SLOTS_V2
    );
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/freeTimeSlots/visitV2",
        data: { ...freeVisitTimeSlotRequest },
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllFreeVisitTimeSlots(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting free time slots");
    }
  }
}

// export function* createVisitSaga() {
//   while (true) {
//     const { visit } = yield take(mutations.CREATE_VISIT);
//     try {
//       yield axios({
//         method: "post",
//         url: url + "/v1.0/visits/withCustomerData",
//         data: { ...visit },
//         headers: {
//           Authorization: "Bearer " + JSON.parse(localStorage.getItem("token"))
//         }
//       });

//       $("#visitInformation").modal("show");
//       // toastSuccess("Visit is created");
//     } catch (e) {
//       /* catch block handles failed save */
//       console.log(e);
//       toastError("Visit is not created");
//     }
//   }
// }

export function* createVisitFromServiceTypeListSaga() {
  while (true) {
    const { visit, cb } = yield take(
      mutations.CREATE_VISIT_FROM_SERVICE_TYPE_LIST_AND_CUSTOMER_DATA
    );
    try {
      let result = yield axios({
        method: "post",
        url: url + "/v1.0/visits/fromServiceTypeListAndCustomerData",
        data: { ...visit },
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });

      cb(true, result.data.result);
      // toastSuccess("Visit is created");
    } catch (e) {
      /* catch block handles failed save */
      cb(false, "");
      console.log(e);
      toastError("Visit is not created");
    }
  }
}
